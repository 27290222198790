<template>
  <v-card>
    <v-card-title>Обработка заказа</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <text-line title="Источник" :value="orderSource"/>
        </v-col>
        <v-col>
          <text-line title="Начать доставку" :value="shippingAt"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <text-line title="Менеджер" :value="managerName"/>
        </v-col>
        <v-col>
          <text-line title="Исполнитель" :value="workerName"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" :to="{name:'order.manage', params:{ id: orderId }}">Управлять</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import TextLine from '@/components/ui/TextLine.vue'

export default {
  components: { TextLine },
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  computed: {
    orderId() {
      return this.order?.id ?? 'X'
    },
    orderSource() {
      return this.order?.source ?? 'Не определен'
    },
    managerName() {
      return this.order?.manager?.fullName ?? 'Не назначен'
    },
    workerName() {
      return this.order?.worker?.fullName ?? 'Не назначен'
    },
    shippingAt() {
      const value = this.order?.shippingAt

      if (value) {
        return this.$options.filters.formatDateTime(value)
      }

      return '-'
    }
  }
}
</script>
