<template>
  <div class="mb-2">
    <div class="text-caption grey--text text--darken-2">{{ title }}</div>
    <slot :value="value">
      <div>
        {{ value }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'TextLine',
  props: {
    title: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    value: {
      type: [String, Number, Boolean],
      default() {
        return ''
      }
    }
  }
}
</script>
